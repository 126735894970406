import React from 'react';
import styles from './form.module.scss';

export default function TextInput({ name, required, icon, value, handleChange }) {
  return(
    <div>
      {icon ? <img style={{position: 'absolute', paddingTop: 10, paddingLeft: 10}} src={icon} alt="form icon" /> : null}
      <input
        className={`${styles.input} ${styles.formControl}`}
        style={icon ? {paddingLeft: 32} : null}
        type={'text'}
        name={name}
        required={required}
        value={value}
        onChange={(event) => handleChange(event.target.value)}
      />
    </div>
  );
}
