import React from 'react';
import { useIntl } from 'react-intl';
import { useLocalization } from 'gatsby-theme-i18n';
import { navigate } from 'gatsby';
import styles from './steps.module.scss';
import FormContainer from '../Form/form-container';
import FormHeadline from '../FormHeadline/form-headline';
import FormField from '../Form/form-field';
import TextInput from '../Form/text-input';
import TextArea from '../Form/text-area';
import Button from '../Button/button';

import Form from '../Form/form';

function encode(data) {
  return Object.keys(data)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
}

export default function ContactInfo({ set, data, name, email, phone, notes }) {
  const intl = useIntl();
  const { locale, defaultLang } = useLocalization();

  const handleSubmit = () => {
    const d = {
      subject: `Personal chef request from ${data.name} (${data.meal} for ${data.peopleCount})`,
      address: data.address,
      peopleCount: data.peopleCount,
      meal: data.meal,
      hob: data.hob,
      hobCount: data.hobCount,
      oven: data.oven,
      date: data.date.toLocaleString(),
      courses: data.courses,
      dietaryRestrictions: data.dietaryRestrictions,
      vegetarian: data.vegetarian,
      vegan: data.vegan,
      noGluten: data.noGluten,
      noNuts: data.noNuts,
      noShellfish: data.noShellfish,
      noDairy: data.noDairy,
      otherRestrictions: data.otherRestrictions,
      name: data.name,
      email: data.name,
      phone: data.phone,
      notes: data.notes
    };

    const thanks = locale === defaultLang ? `/get-quote/thanks` : `/${locale}/get-quote/thanks`;
    const error = locale === defaultLang ? `/get-quote/error` : `/${locale}/get-quote/error`;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "quote", ...d })
    })
      .then(() => navigate(thanks))
      .catch(() => navigate(error));

  };

  return(
    <FormContainer>
      <FormHeadline>{intl.formatMessage({ id: "quoteContactInfoHeadline" })}</FormHeadline>
      <FormField label={intl.formatMessage({ id: "quoteContactInfoName"})} name={'name'}>
        <TextInput
          fieldId={'name'}
          handleChange={(value) => set({name: value})}
          value={name}
          required
        />
      </FormField>

      <FormField label={intl.formatMessage({ id: "quoteContactInfoPhone"})} name={'phone'}>
        <TextInput
          fieldId={'phone'}
          handleChange={(value) => set({phone: value})}
          value={phone}
          required
        />
      </FormField>

      <FormField label={intl.formatMessage({ id: "quoteContactInfoEmail"})} name={'email'}>
        <TextInput
          fieldId={'email'}
          handleChange={(value) => set({email: value})}
          value={email}
          required
        />
      </FormField>

      <FormField label={intl.formatMessage({ id: "quoteContactInfoNotes"})} name={"notes"}>
        <TextArea
          name={"notes"}
          handleChange={(value) => set({notes: value})}
          value={notes}
          required={false}
        />
      </FormField>

      <div className={styles.buttonContainer}>
        <Button handleClick={() => handleSubmit()} disabled={false} elevated={false}>
          {intl.formatMessage({ id: "quoteSubmit"})}
        </Button>
      </div>

      <div style={{display: 'none'}}>
        <Form name={"quote"}>
          <input type="text" name="address" />
          <input type="text" name="meal" />
          <input type="text" name="hob" />
          <input type="number" name="hobCount" />
          <input type="checkbox" name="oven" />
          <input type="datetime-local" name="date" />
          <input type="number" name="courses" />
          <input type="text" name="dietaryRestrictions" />
          <input type="checkbox" name="vegetarian" />
          <input type="checkbox" name="vegan" />
          <input type="checkbox" name="noNuts" />
          <input type="checkbox" name="noShellfish" />
          <input type="checkbox" name="noDairy" />
          <input type="checkbox" name="noGluten" />
          <input type="text" name="otherRestrictions" />
          <input type="text" name="name" />
          <input type="email" name="email" />
          <input type="tel" name="phone" />
          <input type="text" name="notes" />
          <input type="submit" />
        </Form>
      </div>
    </FormContainer>
  );
}
