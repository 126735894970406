import React from 'react';
import styles from './form.module.scss';

export default function Form({ children, name }) {
  return(
    <form
      className={styles.formContainer}
      name={name}
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      {/* action="/thanks" */}
      <input type="hidden" name="form-name" value={name} />
      {/* <div style={{display: 'hidden'}} data-netlify-recaptcha="true" /> */}
      {children}
    </form>
  );
}
