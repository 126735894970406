import React from 'react';
import QuoteLayout from '../../components/QuoteLayout/quote-layout';
import ContactInfo from '../../components/GetQuoteSteps/contact-info';
import QuoteContext from '../../context/quote-context';

export default function QuoteContactInfoPage() {
  return (
    <QuoteLayout>
      <QuoteContext.Consumer>
        {quote => (
          <ContactInfo
            set={quote.set}
            data={quote.state}
            name={quote.name}
            email={quote.email}
            phone={quote.phone}
            notes={quote.notes}
          />
        )}
      </QuoteContext.Consumer>
    </QuoteLayout>
  );
}
